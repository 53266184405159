import axios from 'axios'
import { API_USER } from 'config/constants/endpoints'
import useSWR from 'swr'
import { getCookie } from 'cookies-next'
import { ACCESS_TOKEN_KEY } from 'config/constants/auth'
import { UserProfileType } from '../types'

export const useProfileUser = (
  isInitialized: boolean,
): {
  user: UserProfileType | undefined
  loading: boolean
  loggedOut: boolean
  refetch: () => void
} => {
  const { data, isLoading, error, mutate } = useSWR(isInitialized ? ['Profile'] : null, async () => {
    const accessToken = getCookie(ACCESS_TOKEN_KEY)
    if (!accessToken) return undefined

    return axios.get<any, { data: UserProfileType }>(`${API_USER}/profile`)
  })
  const loading = isLoading || !error
  const loggedOut = error?.response?.status === 401

  return {
    user: data?.data,
    loading,
    loggedOut,
    refetch: mutate,
  }
}
