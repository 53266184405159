import useSWRImmutable from 'swr/immutable'
import { FetchStatus } from 'config/constants/types'
import { fetchTokenHistory, fetchTokens } from './helpers'

export const useListTokens = () => {
  const { data } = useSWRImmutable('ListTokens', async () => {
    return fetchTokens()
  })

  return data ?? []
}

export const useGetToken = (code: string) => {
  const tokens = useListTokens()

  return tokens.find((token) => token.code === code)
}

export function useFetchTokenHistory(code: string) {
  const { data, status } = useSWRImmutable(code ? [code, 'TokenHistory'] : null, async () => {
    return fetchTokenHistory(code)
  })

  return {
    isLoading: status === FetchStatus.Fetching,
    history: data ?? [],
  }
}
