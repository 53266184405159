import axios from 'axios'
import { deleteCookie, getCookie, setCookie } from 'cookies-next'
import type { ReactNode } from 'react'
import { createContext, useCallback, useEffect, useState } from 'react'

import { useToast } from '@pancakeswap/uikit'
import { ACCESS_TOKEN_KEY, REFRESH_TOKEN_KEY } from 'config/constants/auth'
import { useSWRConfig } from 'swr'
import { API_USER } from 'config/constants/endpoints'
import { useUserWallets } from 'state/user/hooks'
import type { AuthValuesType, ErrCallbackType, LoginParams } from './types'
import { useProfileUser } from './hooks/useProfileUser'

// ** Defaults
const defaultProvider: AuthValuesType = {
  loading: true,
  user: null,
  wallets: [],
  refresh: () => Promise.resolve(),
  refreshWallet: () => Promise.resolve(),
  login: () => Promise.resolve(),
  logout: () => Promise.resolve(),
}

const AuthContext = createContext(defaultProvider)

type Props = {
  children: ReactNode
}

axios.interceptors.response.use(
  (response) => {
    return response.data;
  }
)

const AuthProvider = ({ children }: Props) => {
  // ** States
  const [isInitialized, setIsInitialized] = useState(false)

  // ** Hooks
  const { mutate } = useSWRConfig()
  const { toastError } = useToast()
  const { user, loading, loggedOut, refetch } = useProfileUser(isInitialized)
  const { wallets, refresh } = useUserWallets(user?.username)

  const clearCache = useCallback(() => {
    mutate(
      (key) => typeof key === 'object' && key?.[0] && typeof key?.[0] === 'string' && key?.[0].startsWith('0x'),
      undefined,
      { revalidate: false },
    )
  }, [mutate])

  useEffect(() => {
    const setInterceptors = () => {
      axios.interceptors.response.use(
        (response) => {
          return response;
        },
        async (err) => {
          const prevRequest = err?.config
          if (err.response) {
            // Access Token was expired
            const data = err.response?.data
            if (
              err.response.status === 401 &&
              data?.statusCode === 5100 &&
              // eslint-disable-next-line no-underscore-dangle
              !prevRequest._retry
            ) {
              // eslint-disable-next-line no-underscore-dangle
              prevRequest._retry = true

              try {
                let headers = {}
                const refreshToken = getCookie(REFRESH_TOKEN_KEY)
                axios.defaults.headers.common.Authorization = `Bearer ${refreshToken}`
                const res = await axios.post(`${API_USER}/auth/refresh`)
                const { accessToken } = res.data
                setCookie(ACCESS_TOKEN_KEY, accessToken)
                axios.defaults.headers.common.Authorization = `Bearer ${accessToken}`
                headers = {
                  ...prevRequest.headers,
                  Authorization: `Bearer ${accessToken}`,
                }

                return await axios({
                  ...prevRequest,
                  headers,
                  sent: true,
                })
              } catch (_error) {
                console.log('_error', _error)

                deleteCookie(ACCESS_TOKEN_KEY)
                deleteCookie(REFRESH_TOKEN_KEY)

                throw _error
              }
            }
          }

          if (err.response && err.response.data) {
            throw err.response.data
          }
          throw err
        },
      )
    }

    const initAuth = async (): Promise<void> => {
      axios.defaults.headers.common['x-api-key'] = process.env.NEXT_PUBLIC_API_KEY;

      setInterceptors()
      const accessToken = getCookie(ACCESS_TOKEN_KEY)
      if (accessToken) {
        axios.defaults.headers.common.Authorization = `Bearer ${accessToken}`
      }

      setIsInitialized(true)
    }

    initAuth()
  }, [])

  const handleLogin = (body: LoginParams, errorCallback?: ErrCallbackType) => {
    axios
      .post(`${API_USER}/login/signature`, body)
      .then(async (res) => {
        const { accessToken, refreshToken } = res.data
        if (accessToken) setCookie(ACCESS_TOKEN_KEY, accessToken)
        if (refreshToken) setCookie(REFRESH_TOKEN_KEY, refreshToken)

        axios.defaults.headers.common.Authorization = `Bearer ${accessToken}`

        refetch()
      })
      .catch((err: any) => {
        toastError(err?.message)

        return errorCallback ? errorCallback(err?.errors) : null
      })
  }

  // const handleSignUp = (body: SignUpParams, successCallback: (_none: string) => void) => {
  //   axios
  //     .post(`${USER_PUBLIC_API}/sign-up`, body)
  //     .then((res) => {
  //       const none = res?.data?.none || ''
  //       return successCallback(none)
  //     })
  //     .catch((err: any) => {
  //       toastError(err?.message)
  //     })
  // }

  const handleLogout = useCallback(
    (clearAll = true) => {
      if (clearAll) clearCache()

      deleteCookie(ACCESS_TOKEN_KEY)
      deleteCookie(REFRESH_TOKEN_KEY)
      refetch()
    },
    [clearCache],
  )

  useEffect(() => {
    if (loggedOut) {
      handleLogout(true)
    }
  }, [handleLogout, loggedOut])


  const values = {
    user,
    wallets,
    loading,
    login: handleLogin,
    refresh: () => refetch(),
    refreshWallet: () => refresh(),
    logout: handleLogout,
  }

  return <AuthContext.Provider value={values}>{children}</AuthContext.Provider>
}

export { AuthContext, AuthProvider }
