import axios from 'axios'

import { API_TOKEN_PUBLIC } from 'config/constants/endpoints'

import type { TokenType, TokenHistoryType } from './types'

export const fetchTokens = async (): Promise<TokenType[]> => {
  const response = await axios.get<any, any>(`${API_TOKEN_PUBLIC}/prices`)

  return response?.data ?? []
}

export const fetchTokenHistory = async (code: string): Promise<TokenHistoryType[]> => {
  try {
    const response = await axios.get<any, any>(`${API_TOKEN_PUBLIC}/price-history/${code}`)

    return response?.data ?? []
  } catch (error) {
    console.error('Get Token History Failed', error)

    return []
  }
}
