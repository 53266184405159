import React from "react";
import Svg from "../Svg";
import { SvgProps } from "../types";

const Icon: React.FC<React.PropsWithChildren<SvgProps>> = (props) => {
  return (
    <Svg viewBox="0 0 24 24" {...props}>
      <g>
        <path d="M10 6V0h4v6a2 2 0 0 1-4 0zm6-1h8a5.006 5.006 0 0 0-5-5h-3zM8 0H5a5.006 5.006 0 0 0-5 5h8zm16 7v12a5.006 5.006 0 0 1-5 5H5a5.006 5.006 0 0 1-5-5V7h8.142a3.981 3.981 0 0 0 7.716 0zm-4 12a1 1 0 0 0-1-1h-3a1 1 0 0 0 0 2h3a1 1 0 0 0 1-1z" />
      </g>
    </Svg>
  );
};

export default Icon;
