import axios from 'axios'

import { API_USER } from 'config/constants/endpoints'
import { UserProfileType } from 'contexts/Auth/types'
import { ResponsePaging } from 'config/constants/types'
import type { UserReferrerListType, UserWalletType } from './types'

// use this for update user info
export const getUserProfile = async (): Promise<UserProfileType> => {
  const response = await axios.get(`${API_USER}/profile`)

  return response?.data
}

export const sendEmailValidation = async (body: any): Promise<string> => {
  const response = await axios.post(`${API_USER}/send-email-code`, body)

  return response?.data?.timeRetry
}

export const checkEmailValidation = async (
  code: string,
): Promise<{
  amount: string
  symbol: string
}> => {
  const response = await axios.get(`${API_USER}/activate/${code}`)

  return response?.data
}

export const changeUserInfo = async (body: any): Promise<UserProfileType> => {
  const formData = new FormData()
  Object.keys(body).forEach((field) => {
    formData.append(field, body[field])
  })

  const response = await axios.patch(`${API_USER}/update`, formData)

  return response?.data
}

export const getUserWallets = async (): Promise<UserWalletType[]> => {
  const response = await axios.get(`${API_USER}/wallets`)

  return response?.data
}

export const getUserWalletByCode = async (code: string): Promise<UserWalletType> => {
  const response = await axios.get(`${API_USER}/wallet/${code}`)

  return response?.data
}

export const fetchAllReferrers = async (email: string, params: any): Promise<ResponsePaging<UserReferrerListType>> => {
  return axios.get<any, any>(`${API_USER}/referrer/list/${email}`, {
    params,
  })
}

export const requestSetup2FA = async (): Promise<{
  otpUrl: string
  secret: string
}> => {
  try {
    const response = await axios.get(`${API_USER}/gg-2fa/setup`)

    return response?.data ?? null
  } catch (error) {
    return null
  }
}

export const requestGG2FAEnabled = async (code: string): Promise<boolean> => {
  return axios.post(`${API_USER}/gg-2fa/enable`, { code })
}

export const requestGG2FADisabled = async (code: string): Promise<boolean> => {
  return axios.post(`${API_USER}/gg-2fa/disabled`, { code })
}

export const requestGG2FAValid = async (code: string): Promise<any> => {
  return axios.post(`${API_USER}/gg-2fa/valid`, { code })
}

export const requestSetReferrer = async (address: string): Promise<any> => {
  return axios.post(`${API_USER}/set-referrer/${address}`)
}

export const fetchHasLinkReferrer = async (): Promise<boolean> => {
  const response = await axios.get(`${API_USER}/has-link-referrer`)

  return response?.data?.canHasRefLink
}
