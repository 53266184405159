import { useTranslation } from '@pancakeswap/localization'
import { ChainId } from '@pancakeswap/sdk'
import {
  Box,
  UserMenu as UIKitUserMenu,
  UserMenuVariant,
  YourNFTs,
  // Settings,
  Disconnect,
  Skeleton,
  CopyButton,
  HistoryIcon,
  WalletIcon,
} from '@pancakeswap/uikit'
import Trans from 'components/Trans'
import { useActiveChainId } from 'hooks/useActiveChainId'
import { useEffect, useState } from 'react'
import { usePendingTransactions } from 'state/transactions/hooks'
import { useAccount, useBalance } from 'wagmi'
import styled from 'styled-components'
import Link from 'next/link'
import { formatBigInt } from '@pancakeswap/utils/formatBalance'
import ConnectWalletAndLoginButton from 'components/ConnectWalletAndLoginButton'
import { useAuthServer } from 'contexts/Auth/hooks/useAuthServer'
import { useLogged } from 'contexts/Auth/hooks/useLogged'

const BlockAccountWrap = styled.div``

const BlockInfoAccount = styled.div``
const BlockInfoAccountWrap = styled.div`
  padding: 15px 10px 10px 10px;
  border: 2px solid ${({ theme }) => theme.colors.background};
  border-radius: 14px;
`

const BlockAccount = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  transition: 0.4s all;
  padding: 10px;
  position: relative;
`
const BlockIDWallet = styled.div`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-family: SUISSE_BOLD;
  font-size: 1.6rem;
  line-height: calc(1.6rem * 1.25);
  color: ${({ theme }) => theme.colors.color};
  margin: 0px 10px 0px 0px;
  transition: 0.4s all;
`

const BlockWallet = styled.div`
  border: 1.5px solid ${({ theme }) => theme.colors.borderSolid};
  border-radius: 10px;
  margin: 5px 0px;
`
const BlockWalletItem = styled.div`
  padding: 10px 15px;
  border-bottom: 1.5px solid ${({ theme }) => theme.colors.borderSolid};
  cursor: pointer;
  transition: 0.4s all;
  &:last-child {
    border-bottom: 1px solid transparent;
  }
`
const BlockWrapInfo = styled.div``
const BlockWrapMoney = styled.div``
const TextName = styled.div`
  font-family: SUISSE_MEDIUM;
  margin: 0px 0px 5px 0px;
  font-size: 1.25rem;
  line-height: calc(1.25rem * 1.25);
  color: ${({ theme }) => theme.colors.color};
`
const TextMoney = styled.div`
  font-family: SUISSE_BOLD;
  font-size: 1.6rem;
  line-height: calc(1.6rem * 1.25);
  color: ${({ theme }) => theme.colors.color};
`

const BlockWrapInfoDirect = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
`

const BlockListDirect = styled.div`
  margin: 15px 0px 5px 0px;
`

const BlockDirectItem = styled(Link)<{ disabled?: boolean }>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  transition: 0.4s all;
  height: 45px;
  padding: 0px 15px;
  border-radius: 10px;
  cursor: pointer;
  background-color: ${({ theme, disabled }) => (disabled ? theme.colors.backgroundAlt2 : theme.colors.backgroundAlt)};
  &:hover {
    background: ${({ theme }) => theme.colors.background};
    transition: 0.4s all;
  }
`
const BlockButtonItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  transition: 0.4s all;
  height: 45px;
  padding: 0px 15px;
  border-radius: 10px;
  cursor: pointer;
  &:hover {
    background: ${({ theme }) => theme.colors.background};
    transition: 0.4s all;
  }
`
const ImageDirect = styled.div`
  margin: 2.5px 15px 0px 0px;
  svg {
    width: 18px;
    height: 18px;
  }
`
const TextDirect = styled.div`
  font-family: SUISSE_BOLD;
  color: ${({ theme }) => theme.colors.color};
`
const BlockBtnCopy = styled.div`
  width: fit-content;
  max-width: 30px;
  position: absolute;
  right: 10px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
`

const BtnConnect = styled(ConnectWalletAndLoginButton)`
  border-radius: 8px;
  font-family: SUISSE_BOLD;
  height: 40px;
  align-items: center;
  padding: 0px 12.5px;
  border-radius: 5px;
  width: fit-content; 
  span {
    font-family: SUISSE_BOLD;
    color: #000;
    
  }
  @media screen and (max-width: 1199px) {
    height: 35px;
    border-radius: 5px;
    font-size: 1.25rem;
    line-height: calc(1.25rem * 1.25);
  }
`

const UserMenuItems = () => {
  const { t } = useTranslation()
  const { chainId, isWrongNetwork } = useActiveChainId()
  const { logout } = useAuthServer()
  const { address: account } = useAccount()

  const bnbBalance = useBalance({ address: account, chainId: ChainId.BSC })

  const textAddress = `${account.slice(0, 5)}...${account.slice(-4)}`

  return (
    <>
      <BlockInfoAccount>
        <BlockInfoAccountWrap>
          <BlockAccount>
            <BlockIDWallet>{textAddress}</BlockIDWallet>
            <BlockBtnCopy>
              <CopyButton text={account} tooltipMessage="Copied" />
            </BlockBtnCopy>
          </BlockAccount>
          {/* <BlockWallet>
            <BlockWalletItem>
              <BlockWrapInfo>
                <TextName>BNB {t('Balance')}</TextName>
              </BlockWrapInfo>
              <BlockWrapMoney>
                {!bnbBalance.isFetched ? (
                  <Skeleton height="22px" width="60px" />
                ) : (
                  <TextMoney>{formatBigInt(bnbBalance?.data?.value ?? 0n, 6)}</TextMoney>
                )}
              </BlockWrapMoney>
            </BlockWalletItem>
          </BlockWallet> */}
          <BlockListDirect>
            <BlockDirectItem
              disabled={isWrongNetwork || chainId !== ChainId.BSC}
              href={`/profile/${account?.toLowerCase()}`}
            >
              <BlockWrapInfoDirect>
                <ImageDirect>
                  <YourNFTs />
                </ImageDirect>
                <TextDirect>{t('Your NFTs')}</TextDirect>
              </BlockWrapInfoDirect>
            </BlockDirectItem>
            <BlockDirectItem href="transaction">
              <BlockWrapInfoDirect>
                <ImageDirect>
                  <HistoryIcon />
                </ImageDirect>
                <TextDirect>Transaction</TextDirect>
              </BlockWrapInfoDirect>
            </BlockDirectItem>
            <BlockButtonItem onClick={logout}>
              <BlockWrapInfoDirect>
                <ImageDirect>
                  <Disconnect />
                </ImageDirect>
                <TextDirect>Disconnect</TextDirect>
              </BlockWrapInfoDirect>
            </BlockButtonItem>
          </BlockListDirect>
        </BlockInfoAccountWrap>
      </BlockInfoAccount>
    </>
  )
}

const UserMenu = () => {
  const { t } = useTranslation()
  const { logged } = useLogged()
  const { user } = useAuthServer()
  const { isWrongNetwork } = useActiveChainId()
  const { hasPendingTransactions, pendingNumber } = usePendingTransactions()
  const [userMenuText, setUserMenuText] = useState<string>('')
  const [userMenuVariable, setUserMenuVariable] = useState<UserMenuVariant>('default')

  useEffect(() => {
    if (hasPendingTransactions) {
      setUserMenuText(t('%num% Pending', { num: pendingNumber }))
      setUserMenuVariable('pending')
    } else {
      setUserMenuText('')
      setUserMenuVariable('default')
    }
  }, [hasPendingTransactions, pendingNumber, t])

  if (logged) {
    return (
      <>
        <UIKitUserMenu
          account={user.address}
          ellipsis={user.address.length > 20}
          text={userMenuText}
          variant={userMenuVariable}
        >
          {({ isOpen }) => (isOpen ? <UserMenuItems /> : null)}
        </UIKitUserMenu>
      </>
    )
  }

  if (isWrongNetwork) {
    return (
      <UIKitUserMenu text={t('Wrong Network')} variant="danger">
        {({ isOpen }) => (isOpen ? <UserMenuItems /> : null)}
      </UIKitUserMenu>
    )
  }

  return (
    <BlockAccount>
      <BlockAccountWrap>
        <BtnConnect>
          <Box display={['none', , , 'flex']}>
            <span>Connect Wallet</span>
          </Box>
          <Box display={['flex', , , 'none']}>
          <span>Connect</span>
          </Box>
        </BtnConnect>
      </BlockAccountWrap>
    </BlockAccount>
  )
}

export default UserMenu
