import React from "react";
import Svg from "../Svg";
import { SvgProps } from "../types";

const Icon: React.FC<React.PropsWithChildren<SvgProps>> = (props) => {
  return (
    <Svg viewBox="0 0 24 24" {...props}>
      <g>
        <path d="M12 0C5.383 0 0 5.383 0 12s5.383 12 12 12 12-5.383 12-12S18.617 0 12 0Zm4 13h-4a1 1 0 0 1-1-1V6a1 1 0 1 1 2 0v5h3a1 1 0 1 1 0 2Z" />
      </g>
    </Svg>
  );
};

export default Icon;
