import { DefaultSeoProps } from 'next-seo'

export const SEO: DefaultSeoProps = {
  titleTemplate: '%s | Green Delta',
  defaultTitle: 'Green Delta',
  description:
    'Welcome to Green Delta, where innovation meets sustainability in a unique fusion of NFTs and carbon credits. Our platform is the ultimate marketplace for artists, collectors, and eco-conscious individuals',
  twitter: {
    cardType: 'summary_large_image',
    handle: '@Green Delta',
    site: '@Green Delta',
  },
  openGraph: {
    title: 'Green Delta',
    description: 'Green Delta.',
    images: [{ url: 'https://greendelta.io/assets/images/bgHero.png' }],
  },
}
