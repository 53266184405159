import React from "react";
import Svg from "../Svg";
import { SvgProps } from "../types";

const Icon: React.FC<React.PropsWithChildren<SvgProps>> = (props) => {
  return (
    <Svg viewBox="0 0 24 24" {...props}>
      <g xmlns="http://www.w3.org/2000/svg"><path d="M19 16h-6c-2.757 0-5-2.243-5-5V5c0-2.757 2.243-5 5-5h6c2.757 0 5 2.243 5 5v6c0 2.757-2.243 5-5 5Zm-8 8H5c-2.757 0-5-2.243-5-5v-6c0-2.757 2.243-5 5-5a1 1 0 0 1 1 1v2c0 3.86 3.141 7 7 7h2a1 1 0 0 1 1 1c0 2.757-2.243 5-5 5Z" /></g>
    </Svg>
  );
};

export default Icon;
