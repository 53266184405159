import React from "react";
import Svg from "../Svg";
import { SvgProps } from "../types";

const Icon: React.FC<React.PropsWithChildren<SvgProps>> = (props) => {
  return (
    <Svg viewBox="0 0 24 24" {...props}>
      <g>
        <path d="M20.5 5h-1.935a3.332 3.332 0 0 0-.236-3.657 3.334 3.334 0 0 0-2.211-1.31A3.347 3.347 0 0 0 13.63.67c-.696.522-1.226 1.196-1.63 1.882-.404-.685-.934-1.36-1.63-1.881A3.34 3.34 0 0 0 7.882.034a3.332 3.332 0 0 0-2.21 1.309 3.333 3.333 0 0 0-.638 2.488c.06.419.2.813.399 1.169H3.5C1.57 5 0 6.57 0 8.5V9c0 1.103.897 2 2 2h20c1.103 0 2-.897 2-2v-.5C24 6.57 22.43 5 20.5 5Zm-5.671-2.729c.289-.217.648-.31 1.006-.257.358.051.676.239.894.529.218.29.309.647.258 1.005a1.374 1.374 0 0 1-.561.918c-.037.029-.37.278-1.018.533H13.1c.222-.757.75-1.994 1.73-2.729ZM7.542 4.442a1.347 1.347 0 0 1-.528-.894c-.051-.358.04-.715.259-1.006.217-.29.534-.478.893-.529.354-.052.716.04 1.006.258.98.735 1.507 1.971 1.73 2.729H8.594c-.648-.255-.984-.506-1.05-.558Zm5.782 13.203a.916.916 0 0 1 .593-1.616h2.751l.974-2.732a.916.916 0 0 1 1.718 0l.974 2.732h2.751a.917.917 0 0 1 .59 1.618l-2.169 1.767.897 2.732a.914.914 0 0 1-.311 1.014.92.92 0 0 1-1.06.042l-2.524-1.644-2.482 1.661a.917.917 0 0 1-1.385-1.037l.862-2.765-2.179-1.773ZM13.049 24H7a5 5 0 0 1-5-5v-6h13.624l-.367 1.029h-1.34c-1.22 0-2.32.77-2.737 1.91-.421 1.143-.08 2.441.882 3.258l1.129.918-.458 1.47A2.918 2.918 0 0 0 13.049 24Z" />
      </g>
    </Svg>
  );
};

export default Icon;
