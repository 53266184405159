import { useTranslation } from '@pancakeswap/localization'
import { WalletModalV2 } from '@pancakeswap/ui-wallets'
import { Button, ButtonProps } from '@pancakeswap/uikit'
import { ConnectorNames, createWallets, getDocLink } from 'config/wallet'
import useAuth from 'hooks/useAuth'
// @ts-ignore
// eslint-disable-next-line import/extensions
import { useActiveHandle } from 'hooks/useEagerConnect.bmp.ts'
import { useCallback, useMemo, useState } from 'react'
import { useAccount, useConnect, useSignMessage } from 'wagmi'
import { useAuthServer } from 'contexts/Auth/hooks/useAuthServer'
import { CLIENT_MESSAGE_LOGIN } from 'config/constants/messages'
import useActiveWeb3React from 'hooks/useActiveWeb3React'
import Trans from './Trans'

const ConnectWalletAndLoginButton = ({ children, ...props }: ButtonProps) => {
  const handleActive = useActiveHandle()
  const { login } = useAuth()
  const {
    t,
    currentLanguage: { code },
  } = useTranslation()
  const { connectAsync } = useConnect()
  const [open, setOpen] = useState(false)

  const { account, chainId } = useActiveWeb3React()
  const { login: loginServer } = useAuthServer()
  const { signMessageAsync } = useSignMessage()
  const docLink = useMemo(() => getDocLink(code), [code])

  const handleLoginUser = useCallback(
    async (address: string) => {
      const msg = CLIENT_MESSAGE_LOGIN.join('\n')
        .replace('{WALLET_ADDRESS}', address.toLowerCase())
      const signature = await signMessageAsync({ message: msg })
      loginServer({ address, signature })
    },
    [loginServer, signMessageAsync],
  )

  const handleLogin = async (connectorID: ConnectorNames) => {
    const v = await login(connectorID)
    handleLoginUser(v.account)
  }

  const handleClick = () => {
    if (account) {
      handleLoginUser(account)
      return
    }

    if (typeof __NEZHA_BRIDGE__ !== 'undefined' && !window.ethereum) {
      handleActive()
    } else {
      setOpen(true)
    }
  }

  const wallets = useMemo(() => createWallets(chainId, connectAsync), [chainId, connectAsync])

  return (
    <>
      <Button onClick={handleClick} {...props} style={{ boxShadow: 'none' }}>
        {children || <Trans>Connect Wallet</Trans>}
      </Button>
      <style jsx global>{`
        w3m-modal {
          position: relative;
          z-index: 99;
        }
      `}</style>
      <WalletModalV2
        docText={t('Learn How to Connect')}
        docLink={docLink}
        isOpen={open}
        wallets={wallets}
        login={handleLogin}
        onDismiss={() => setOpen(false)}
      />
    </>
  )
}

export default ConnectWalletAndLoginButton
