import { useAuthServer } from 'contexts/Auth/hooks/useAuthServer'
import useActiveWeb3React from 'hooks/useActiveWeb3React'

export const useLogged = () => {
  const { user } = useAuthServer()
  const { account } = useActiveWeb3React()

  const logged = !!user?.address
  const isWrongAccount = user && account && user?.address?.toLowerCase() !== account?.toLowerCase()

  return {
    logged,
    isWrongAccount,
  }
}
