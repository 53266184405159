import React from "react";
import Svg from "../Svg";
import { SvgProps } from "../types";

const Icon: React.FC<React.PropsWithChildren<SvgProps>> = (props) => {
  return (
    <Svg viewBox="0 0 24 24" {...props}>
      <g>
        <path
          d="M21.25 5.5c0 .015 0 .031.002.046a.669.669 0 0 0 .241.507.751.751 0 0 0 .507.197s.75-.043.75-.75a4.25 4.25 0 0 0-4.25-4.25H5a3.75 3.75 0 0 0 0 7.5h12a.75.75 0 0 0 0-1.5H5a2.25 2.25 0 1 1 0-4.5h13.5a2.75 2.75 0 0 1 2.75 2.75z"
        />
        <path
          d="M2.75 5a.75.75 0 0 0-1.5 0v14A3.75 3.75 0 0 0 5 22.75h14A3.75 3.75 0 0 0 22.75 19v-8A3.75 3.75 0 0 0 19 7.25H5A2.25 2.25 0 0 1 2.75 5zM18 13.5a1.5 1.5 0 1 1-.001 3.001A1.5 1.5 0 0 1 18 13.5z"
        />
        <path
          d="M6 5.75h12a.75.75 0 0 0 0-1.5H6a.75.75 0 0 0 0 1.5z"
        />
      </g>
    </Svg>
  );
};

export default Icon;
