import React from "react";
import Svg from "../Svg";
import { SvgProps } from "../types";

const Icon: React.FC<React.PropsWithChildren<SvgProps>> = (props) => {
  return (
    <Svg viewBox="0 0 24 24" {...props}>
      <path d="M15.5 23a1 1 0 0 1-1 1h-5a1 1 0 0 1-1-1c0-1.5 1.737-3 3.5-3s3.5 1.5 3.5 3ZM3.231 19a2.5 2.5 0 1 0 0-5 2.5 2.5 0 0 0 0 5ZM12 19a2.5 2.5 0 1 0 0-5 2.5 2.5 0 0 0 0 5Zm7-19H5a5 5 0 0 0-5 5v8.381A4.48 4.48 0 0 1 3.231 12a4.503 4.503 0 0 1 4.385 3.517C8.067 13.508 9.858 12 12.001 12s3.934 1.508 4.385 3.517A4.503 4.503 0 0 1 20.771 12a4.48 4.48 0 0 1 3.231 1.381V5a5 5 0 0 0-5-5Zm1.769 19a2.5 2.5 0 1 0 0-5 2.5 2.5 0 0 0 0 5Zm-.019 1c-1.667 0-3.183 1.539-3.25 3a1 1 0 0 0 1 1H23a1 1 0 0 0 1-1c-.079-1.458-1.585-3-3.25-3Zm-17.5 0C1.583 20 .067 21.539 0 23a1 1 0 0 0 1 1h4.5a1 1 0 0 0 1-1c-.079-1.458-1.585-3-3.25-3Z" />
    </Svg>
  );
};

export default Icon;
